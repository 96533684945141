export class Tile {
  x: number;
  y: number;
  z: number;
  id?: string;
  url?: string;
  score?: number;
  count?: number;
  constructor(x: number, y: number, z: number, url?: string, id?: string, score?: number) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.url = url;
    this.id = id;
    this.score = score;
  }
}

export class TilePrediction {
  request_id: string;
  result: {
    prediction: Array<Array<number>>;
  };
}
