import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../model/config.model';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppConfigService {
  private BASE_API = environment.configUrl;

  private config: Observable<Config>;
  constructor(private http: HttpClient) {}

  getConfig(): Observable<Config> {
    if (!this.config) {
      this.config = this.http.get<Config>(`${this.BASE_API}/configs.json`).pipe(share());
    }
    return this.config;
  }
}
