import { Injectable } from '@angular/core';
import { Layer, tileLayer, LatLngBounds } from 'leaflet';
import { LeafletLayers } from 'src/app/model/layers.model';
import { LAYER_CONTROL_BASE, LAYER_CONTROL_OVERLAY, MAX_ZOOM } from '../../constant/app.constants';

@Injectable()
export class MapService {
  private LAYER_OSM = {
    id: 'openstreetmap',
    name: 'OpenStreetMap',
    enabled: false,
    layer: tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: MAX_ZOOM,
      attribution: 'Open Street Map',
    }),
  };
  private LAYER_GM_STREET = {
    id: 'googlestreetmaps',
    name: 'Route',
    enabled: false,
    layer: tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: MAX_ZOOM,
      attribution: 'Route',
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }),
  };
  private LAYER_GM_SATELLITE = {
    id: 'googlesatellitemaps',
    name: 'Google',
    enabled: false,
    layer: tileLayer('https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
      maxZoom: MAX_ZOOM,
      attribution: 'Satellite',
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }),
  };
  private LAYER_GM_TERRAIN = {
    id: 'googlestreetmaps',
    name: 'Terrain',
    enabled: false,
    layer: tileLayer('https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
      maxZoom: MAX_ZOOM,
      attribution: 'Terrain',
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }),
  };

  private defaulOverlayLayers = [];

  //IGN_HOST = 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?Service=WMTS&Request=GetTile&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&';
  IGN_HOST =
    'https://data.geopf.fr/wmts?Service=WMTS&Request=GetTile&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&';
  layerIgnSateliteUri = 'layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&Format=image%2Fjpeg';
  layerIgnCadastreUri = 'layer=CADASTRALPARCELS.PARCELS&style=bdparcellaire&Format=image%2Fpng';

  private LAYER_IGN_SATELLITE = {
    id: 'ignsatelite',
    name: 'IGN',
    enabled: false,
    layer: tileLayer(this.IGN_HOST + this.layerIgnSateliteUri, {
      maxZoom: MAX_ZOOM,
      attribution: 'IGN',
    }),
  };

  private LAYER_IGN_CADASTRAL = {
    id: 'igncadastre',
    name: 'Terrain',
    enabled: false,
    layer: tileLayer(this.IGN_HOST + this.layerIgnCadastreUri, {
      maxZoom: MAX_ZOOM,
      attribution: 'Cadastre',
    }),
  };

  layers: Layer[];
  layersControl: any = {
    baseLayers: {
      Route: this.LAYER_GM_STREET.layer,
      Google: this.LAYER_GM_SATELLITE.layer,
      IGN: this.LAYER_IGN_SATELLITE.layer,
      Terrain: this.LAYER_GM_TERRAIN.layer,
      OpenStreetMap: this.LAYER_OSM.layer,
    },
    overlays: { Cadastre: this.LAYER_IGN_CADASTRAL.layer },
  };
  fitBounds: LatLngBounds;
  private $lastFitBounds: LatLngBounds;
  private $lastLayerName: string;
  private $lastOverlay = false;

  model = new LeafletLayers(
    [
      this.LAYER_GM_STREET,
      this.LAYER_GM_SATELLITE,
      this.LAYER_IGN_SATELLITE,
      this.LAYER_GM_TERRAIN,
      this.LAYER_OSM,
    ],
    this.LAYER_GM_STREET.id,
    [this.LAYER_IGN_CADASTRAL],
  );

  constructor() {
    this.defaulOverlayLayers = [...this.model.overlayLayers];
  }

  resetOldView() {
    this.fitBounds = this.$lastFitBounds;
    this.$lastFitBounds = undefined;
    this.switchLayerByClick(LAYER_CONTROL_BASE, this.$lastLayerName, true);
    if (this.$lastOverlay) {
      this.switchLayerByClick(
        LAYER_CONTROL_OVERLAY,
        this.LAYER_IGN_CADASTRAL.layer.getAttribution(),
      );
    }
  }

  prepare() {
    this.clear();
    this.apply();
  }

  private clear() {
    this.model.overlayLayers = this.defaulOverlayLayers;
  }

  private apply(): void {
    // Get the active base layer
    const baseLayer = this.model.baseLayers.find((l: any) => l.id === this.model.baseLayer);
    // Get all the active overlay layers
    const newLayers = this.model.overlayLayers
      .filter((l: any) => l.enabled)
      .map((l: any) => l.layer);
    if (baseLayer !== undefined) {
      newLayers.unshift(baseLayer.layer);
    }
    this.layers = newLayers;
  }

  // Leaflet bug when map has been reset, user need to click twice on layer controls.
  // In case of base layer we have also to click on an other layer (so you can activate alterLayer)
  // So we use recursion to bypass the Bug (& click twice).
  private switchLayerByClick(
    ctrlDiv: string,
    layerName: string,
    bugLeafletAlterLayer = false,
    bugLeafletClicks = 3,
  ) {
    if (bugLeafletClicks > 0) {
      let alterLayer = '';
      if (bugLeafletClicks === 2 && bugLeafletAlterLayer) {
        alterLayer = this.model.baseLayers.find((l) => l.name !== layerName).name;
      }
      const control = document.getElementsByClassName(ctrlDiv);
      if (control.length > 0) {
        control[0].childNodes.forEach((label) => {
          if (label.childNodes.length > 0) {
            const div = label.childNodes[0];
            if (div.childNodes.length > 1) {
              const span = div.childNodes[1] as HTMLSpanElement;
              if (span.textContent === alterLayer || span.textContent === layerName) {
                span.click();
              }
            }
          }
        });
      }
      this.switchLayerByClick(ctrlDiv, layerName, bugLeafletAlterLayer, bugLeafletClicks - 1);
    }
  }

  get lastFitBounds(): LatLngBounds {
    return this.$lastFitBounds;
  }
  set lastFitBounds(x: LatLngBounds) {
    this.$lastFitBounds = x;
  }

  get lastLayerName(): string {
    return this.$lastLayerName;
  }
  set lastLayerName(x: string) {
    this.$lastLayerName = x;
  }

  get lastOverlay(): boolean {
    return this.$lastOverlay;
  }
  set lastOverlay(x: boolean) {
    this.$lastOverlay = x;
  }
}
