import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { ReferentialService } from '../../shared/services/referentials/referential.service';
import { SessionService } from '../../shared/services/authentication/session.service';
import { User } from 'src/app/model/user.model';
import { LocalizationService } from 'src/app/shared/services/localization/localization.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Input() formUser: FormGroup<{
    username: FormControl<string>;
    name: FormControl<string>;
    firstname: FormControl<string>;
    picture: FormControl<string>;
    deletePicture: FormControl<boolean>;
    email: FormControl<string>;
    lang: FormControl<string>;
    roles: FormControl<string[]>;
    enabled: FormControl<boolean>;
  }>;
  @Input() action: string;
  hasApic = false;

  userEdit: boolean;
  profiles: string[];
  pictureData: ArrayBuffer;
  pictureDataBase64: string;
  pictureDelete = false;
  isSelf = false;
  pictureUrl: string;
  error: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private referentialService: ReferentialService,
    public localizationService: LocalizationService,
    private session: SessionService,
  ) {}

  ngOnInit() {
    if (!this.hasApic) {
      this.formUser.get('deletePicture').disable();
    }
    if (this.action === 'SELF_EDIT') {
      this.isSelf = true;
      this.action = 'EDIT';
    }
    if (this.action === 'EDIT') {
      this.formUser.get('username').disable();
      this.formUser.get('email').disable();
      if (this.userEdit) {
        this.formUser.get('enabled').disable();
      }
    } else {
      this.formUser.get('deletePicture').disable();
    }
    this.referentialService.getProfiles().subscribe({
      next: (profiles) => (this.profiles = profiles),
    });
    // retrieve the url (if there is one) and reset the picture field
    this.pictureUrl = this.formUser.get('picture').value;
    this.formUser.get('picture').reset();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.error = false;
      const file = event.target.files[0];

      // Size check
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width > 3000 || img.height > 3000 || img.width < 64 || img.height < 64) {
          this.formUser.get('picture').reset();
          this.error = true;
        }
      };
      const reader = new FileReader();
      const base64Reader = new FileReader();

      reader.readAsArrayBuffer(file);
      base64Reader.readAsDataURL(file);
      reader.onload = () => {
        this.pictureData = reader.result as ArrayBuffer;
      };
      base64Reader.onload = () => {
        this.pictureDataBase64 = base64Reader.result as string;
      };
    }
  }

  closeAlert() {
    this.error = false;
  }

  close() {
    let roles = [];
    for (let profile of this.profiles) {
      if (this.isSelf) {
        roles.push(profile);
      } else if ((document.getElementById(profile) as HTMLInputElement).checked) {
        roles.push(profile);
      }
    }
    if (!roles.includes('ADMIN') && this.isSelfAdmin()) {
      roles.unshift('ADMIN');
    }
    const user: User = {
      username: this.formUser.get('username').value,
      name: this.formUser.get('name').value,
      firstname: this.formUser.get('firstname').value,
      picture: this.pictureUrl,
      email: this.formUser.get('email').value,
      roles: roles,
      enabled: this.formUser.get('enabled').value,
      lang: this.formUser.get('lang').value,
    };
    if (!this.isSelf && this.isSelfAdmin()) {
      this.keepSelected('ADMIN');
    }
    this.activeModal.close({
      user: user,
      deletePicture: this.formUser.get('deletePicture').value,
      pictureData: this.pictureData,
      pictureDataBase64: this.pictureDataBase64,
    });
  }

  activate() {
    this.formUser.get('username').enable();
    this.formUser.get('email').enable();
    this.formUser.get('picture').enable();
    this.formUser.get('deletePicture').enable();
    this.formUser.get('enabled').enable();
  }

  state() {
    let input = document.getElementById('enabled-input') as HTMLInputElement;
    input.value = (!input.value).toString();
  }

  deletePictureState(event: any) {
    if (event.target.checked) {
      this.formUser.get('picture').reset();
      this.pictureDelete = true;
    } else {
      this.pictureDelete = false;
    }
  }

  isSelfAdmin(): boolean {
    const userInfos = this.session.getUserInfo();
    return (
      userInfos.username === this.formUser.get('username').value &&
      userInfos.roles.includes('ADMIN')
    );
  }

  isRole(role: string): boolean {
    return this.formUser.get('roles').value.includes(role);
  }

  keepSelected(self) {
    if (
      (document.getElementById(self) as HTMLInputElement).value === 'ADMIN' &&
      this.isSelfAdmin()
    ) {
      let role = document.getElementById(self) as HTMLInputElement;
      role.checked = true;
      role.disabled = true;
    }
  }

  isDisabled(self) {
    return (
      (document.getElementById(self) as HTMLInputElement).value === 'ADMIN' &&
      this.formUser.get('username').value === this.session.getUserInfo().username
    );
  }
}
