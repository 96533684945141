<nav class="navbar sticky-top navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <div class="navbar-logo-div navbar-brand">
      <span class="material-icons navbar-logo-img">track_changes</span>
      <span class="navbar-title title">{{ 'MENU.TITLE' | translate | uppercase }}</span>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent">
      <div class="navbar-nav mr-auto"></div>
      <ul class="menu-drop-container navbar-nav ms-auto ps-2">
        <div class="image-container" *ngIf="loading">
          <div id="loader-icon" class="spinner-loader m-0 position-static profile-picture"></div>
        </div>
        <button class="image-container" *ngIf="!loading" (click)="edit(user())">
          <img
            *ngIf="user().picture !== ''; else defaultProfilePicture"
            (error)="user().picture = ''"
            class="profile-picture"
            [src]="user().picture"
            id="img"
            alt="{{ user().username }}"
          />
          <ng-template #defaultProfilePicture>
            <em class="fa fa-user-circle fa-3x"></em>
          </ng-template>
          <div class="overlay profile-picture">
            <em class="fa-solid fa-pen fa-bounce"></em>
          </div>
        </button>
        <li ngbDropdown class="nav-item pe-3">
          <button role="button" ngbDropdownToggle class="nav-link" id="account-menu" data-toggle="dropdown" aria-expanded="false">
            <span>{{ user().firstname }}</span>
            <br />
            <span>{{ user().name }}</span>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <a ngbDropdownItem id="referencer-menu-item" *ngIf="isReferencer" routerLink="/referencer/home">
              {{ 'MENU.REFERENCER' | translate }}
            </a>
            <a ngbDropdownItem id="contributor-menu-item" *ngIf="isContributor" routerLink="/contributor/home">
              {{ 'MENU.CONTRIBUTOR' | translate }}
            </a>
            <a ngbDropdownItem id="administration-menu-item" *ngIf="isAdmin" routerLink="/admin">{{ 'MENU.ADMINISTRATION' | translate }}</a>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem id="logout-menu-item" routerLink="/account/logout">{{ 'MENU.LOGOUT' | translate }}</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
