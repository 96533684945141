import { Injectable } from '@angular/core';
import { Observable, forkJoin, EMPTY, Subscriber } from 'rxjs';
import { User } from '../../../model/user.model';
import { AwsUser } from './aws-user.model';
import {
  CognitoIdentityCredentialProvider,
  FromCognitoIdentityPoolParameters,
  fromCognitoIdentityPool,
} from '@aws-sdk/credential-providers';
import {
  AdminCreateUserCommandInput,
  AdminUpdateUserAttributesCommandOutput,
  CognitoIdentityProvider,
  ListUsersCommandInput,
  ListUsersCommandOutput,
  ListUsersInGroupCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import { AppConfigService } from '../../../core/services/app-config.service';
import { ADMIN_ROLE } from '../../constant/app.constants';
import { TokenStorageService } from '../authentication/token-storage.service';
import { expand, map, mergeMap, toArray } from 'rxjs/operators';
import { SessionService } from '../authentication/session.service';
import { Config } from 'src/app/model/config.model';

const ADMIN_GROUP_NAME = 'Admin';

@Injectable({
  providedIn: 'root',
})
export class AwsService {
  public cognitoCreds: CognitoIdentityCredentialProvider;

  constructor(
    private appConfigService: AppConfigService,
    private tokenStorageService: TokenStorageService,
    private storage: SessionService,
  ) {}

  private getConfig(): Observable<Config> {
    const config: Config = this.storage.getItem('configs.json');
    if (config) {
      return new Observable<Config>((obs) => {
        obs.next(config);
        obs.complete();
      });
    } else {
      const configCall: Observable<Config> = this.appConfigService.getConfig();
      configCall.subscribe({
        next: (data) => this.storage.setItem('configs.json', data),
        error: (err) => console.error(err),
      });
      return configCall;
    }
  }

  getAwsConfig(): Observable<[ICognitoUserPoolData, string, string]> {
    return forkJoin([this.getPoolData(), this.getRegion(), this.getIdentityPoolId()]);
  }

  private getIdentityPoolId(): Observable<string> {
    return this.getConfig().pipe(
      map((config) => {
        return config.identityPoolId;
      }),
    );
  }

  private getRegion(): Observable<string> {
    return this.getConfig().pipe(
      map((config) => {
        return config.region;
      }),
    );
  }

  private getPoolData(): Observable<ICognitoUserPoolData> {
    return this.getConfig().pipe(
      map((config) => {
        const poolData: ICognitoUserPoolData = {
          UserPoolId: config.userPoolId,
          ClientId: config.clientId,
        };
        return poolData;
      }),
    );
  }

  setCognitoCreds(creds: CognitoIdentityCredentialProvider) {
    this.cognitoCreds = creds;
  }

  getCognitoCreds(): CognitoIdentityCredentialProvider {
    return this.cognitoCreds;
  }

  buildCognitoCreds(
    idTokenJwt: string,
    poolData: ICognitoUserPoolData,
    region: string,
    identityPool: string,
  ) {
    const url = 'cognito-idp.' + region.toLowerCase() + '.amazonaws.com/' + poolData.UserPoolId;
    const logins: Record<string, string> = {};
    logins[url] = idTokenJwt;
    const params: FromCognitoIdentityPoolParameters = {
      identityPoolId: identityPool,
      logins: logins,
      clientConfig: {
        region: region,
      },
    };
    const creds = fromCognitoIdentityPool(params);
    this.setCognitoCreds(creds);
  }

  getUsers(): Observable<User[]> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region, identityPool]) => {
        return this.getAllUsers(poolData, region, identityPool);
      }),
    );
  }

  getAllUsersByPagination(
    firstPage: boolean,
    data: any,
    poolData: ICognitoUserPoolData,
    region: string,
    identityPool: string,
  ): Observable<ListUsersCommandOutput> {
    const cognitoidentityserviceprovider = new CognitoIdentityProvider({
      credentials: this.getCognitoCreds(),
      region: region,
    });
    const params: ListUsersCommandInput = {
      UserPoolId: poolData.UserPoolId,
    };
    if (data.PaginationToken) {
      params.PaginationToken = data.PaginationToken;
    } else if (!firstPage) {
      return EMPTY;
    }
    return new Observable<ListUsersCommandOutput>((obs) => {
      cognitoidentityserviceprovider.listUsers(params, (err, dataFound) => {
        if (err) {
          console.error(err);
          return obs.error(err);
        } else {
          obs.next(dataFound);
          return obs.complete();
        }
      });
    });
  }

  private getAllUsers(
    poolData: ICognitoUserPoolData,
    region: string,
    identityPool: string,
  ): Observable<User[]> {
    return this.getAllUsersByPagination(true, {}, poolData, region, identityPool).pipe(
      expand((data) => {
        return this.getAllUsersByPagination(false, data, poolData, region, identityPool);
      }),
      mergeMap((data) => data.Users),
      toArray(),
      map((users) =>
        users.map((user) => {
          return {
            username: user.Username,
            email: user.Attributes.find(({ Name }) => Name === 'email').Value,
            enabled: user.Enabled,
            status: user.UserStatus,
            roles: [],
          };
        }),
      ),
      mergeMap((users: User[]) => {
        const cognitoidentityserviceprovider = new CognitoIdentityProvider({
          credentials: this.getCognitoCreds(),
          region: region,
        });
        const p = {
          GroupName: ADMIN_GROUP_NAME,
          UserPoolId: poolData.UserPoolId,
        };
        return new Observable<User[]>((ob) => {
          cognitoidentityserviceprovider.listUsersInGroup(
            p,
            (err: any, d: ListUsersInGroupCommandOutput) => {
              if (err) {
                ob.error(err);
              } else {
                this.addAdminRoleIfInAdminGroup(users, d);
                ob.next(users);
                return ob.complete();
              }
            },
          );
        });
      }),
    );
  }

  /**
   * Add ADMIN role to users if they are in the admin group on cognito
   */
  private addAdminRoleIfInAdminGroup(
    users: User[],
    cognitoListUserOutput: ListUsersInGroupCommandOutput,
  ) {
    users.forEach((user) => {
      if (cognitoListUserOutput.Users.some((admin) => admin.Username === user.username)) {
        user.roles = [ADMIN_ROLE];
      }
      return user;
    });
  }

  createUser(user: User): Observable<AwsUser> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region, identityPool]) => {
        return this.createNewUser(user, poolData, region, identityPool);
      }),
    );
  }

  private createNewUser(
    user: User,
    poolData: ICognitoUserPoolData,
    region: string,
    identityPool: string,
  ): Observable<AwsUser> {
    this.buildCognitoCreds(
      this.tokenStorageService.getAccessToken(),
      poolData,
      region,
      identityPool,
    );
    const cognitoidentityserviceprovider = new CognitoIdentityProvider({
      credentials: this.getCognitoCreds(),
      region: region,
    });
    const params: AdminCreateUserCommandInput = {
      UserPoolId: poolData.UserPoolId,
      Username: user.username,
      DesiredDeliveryMediums: ['EMAIL'],
      ForceAliasCreation: false,
      UserAttributes: [
        {
          Name: 'email',
          Value: user.email,
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
    };
    return new Observable((obs) => {
      cognitoidentityserviceprovider.adminCreateUser(params, (error, data) =>
        this.voidActionAndReturnNext(error, obs, data ? data.User : data),
      );
    }).pipe(
      mergeMap((u: AwsUser) => {
        const p = {
          GroupName: ADMIN_GROUP_NAME,
          UserPoolId: poolData.UserPoolId,
          Username: u.Username,
        };
        return new Observable<AwsUser>((ob) =>
          this.adminAddUserToGroup(p, ob, cognitoidentityserviceprovider, user, u),
        );
      }),
      mergeMap((u: AwsUser) => {
        const p = {
          GroupName: ADMIN_GROUP_NAME,
          UserPoolId: poolData.UserPoolId,
          Username: u.Username,
        };
        return new Observable<AwsUser>((ob) =>
          this.adminRemoveUserFromGroup(p, ob, cognitoidentityserviceprovider, user, u),
        );
      }),
      mergeMap((u: AwsUser) => {
        const p = {
          UserPoolId: poolData.UserPoolId,
          Username: u.Username,
        };
        return new Observable<AwsUser>((ob) =>
          this.adminEnableUser(p, ob, cognitoidentityserviceprovider, user, u),
        );
      }),
      mergeMap((u: AwsUser) => {
        const p = {
          UserPoolId: poolData.UserPoolId,
          Username: u.Username,
        };
        return new Observable<AwsUser>((ob) =>
          this.adminDisableUser(p, ob, cognitoidentityserviceprovider, user, u),
        );
      }),
    );
  }

  editUser(user: User, isAdmin = true): Observable<User> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region]) => {
        return this.editAnyUser(user, poolData, region, isAdmin);
      }),
    );
  }

  deleteUser(user: User): Observable<User> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region]) => {
        return this.deleteAnyUser(user, poolData, region);
      }),
    );
  }

  resendTemporaryPassword(username: string): Observable<User[]> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region]) => {
        return this.resendTemporaryPassword4newUser(username, poolData, region);
      }),
    );
  }

  private resendTemporaryPassword4newUser(
    username: string,
    poolData: ICognitoUserPoolData,
    region: string,
  ): Observable<User[]> {
    const cognitoidentityserviceprovider = new CognitoIdentityProvider({
      credentials: this.getCognitoCreds(),
      region: region,
    });
    const params: AdminCreateUserCommandInput = {
      UserPoolId: poolData.UserPoolId,
      Username: username,
      DesiredDeliveryMediums: ['EMAIL'],
      ForceAliasCreation: false,
      MessageAction: 'RESEND',
    };
    return new Observable((obs) => {
      cognitoidentityserviceprovider.adminCreateUser(params, (error, data) =>
        this.voidActionAndReturnNext(error, obs, data?.User),
      );
    });
  }

  private editAnyUser(
    user: User,
    poolData: ICognitoUserPoolData,
    region: string,
    isAdmin: boolean,
  ): Observable<User> {
    const cognitoidentityserviceprovider = new CognitoIdentityProvider({
      credentials: this.getCognitoCreds(),
      region: region,
    });
    if (!isAdmin) {
      return new Observable((obs) => {
        this.voidActionAndReturnNext('', obs, user);
      });
    }
    const params = {
      UserPoolId: poolData.UserPoolId,
      Username: user.username,
      UserAttributes: [
        {
          Name: 'email',
          Value: user.email,
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
    };
    return new Observable((obs) => {
      cognitoidentityserviceprovider.adminUpdateUserAttributes(
        params,
        (error: any, data: AdminUpdateUserAttributesCommandOutput) =>
          this.voidActionAndReturnNext(error, obs, user),
      );
    }).pipe(
      mergeMap((u: User) => {
        const p = {
          GroupName: ADMIN_GROUP_NAME,
          UserPoolId: poolData.UserPoolId,
          Username: u.username,
        };
        return new Observable<User>((ob) =>
          this.adminAddUserToGroup(p, ob, cognitoidentityserviceprovider, user, u),
        );
      }),
      mergeMap((u: User) => {
        const p = {
          GroupName: ADMIN_GROUP_NAME,
          UserPoolId: poolData.UserPoolId,
          Username: u.username,
        };
        return new Observable<User>((ob) =>
          this.adminRemoveUserFromGroup(p, ob, cognitoidentityserviceprovider, user, u),
        );
      }),
      mergeMap((u: User) => {
        const p = {
          UserPoolId: poolData.UserPoolId,
          Username: u.username,
        };
        return new Observable<User>((ob) =>
          this.adminEnableUser(p, ob, cognitoidentityserviceprovider, user, u),
        );
      }),
      mergeMap((u: User) => {
        const p = {
          UserPoolId: poolData.UserPoolId,
          Username: u.username,
        };
        return new Observable<User>((ob) =>
          this.adminDisableUser(p, ob, cognitoidentityserviceprovider, user, u),
        );
      }),
    );
  }

  private deleteAnyUser(
    user: User,
    poolData: ICognitoUserPoolData,
    region: string,
  ): Observable<User> {
    return new Observable<User>((obs) => {
      const cognitoidentityserviceprovider = new CognitoIdentityProvider({
        credentials: this.getCognitoCreds(),
        region: region,
      });
      const params = {
        Username: user.username,
        UserPoolId: poolData.UserPoolId,
      };
      cognitoidentityserviceprovider.adminDeleteUser(params, (err, data) =>
        this.voidActionAndReturnNext(err, obs, data),
      );
    });
  }

  authenticate(user: string, password: string): Observable<void> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region, identityPool]) => {
        return this.authenticateUserPool(user, password, poolData, region, identityPool);
      }),
    );
  }

  private authenticateUserPool(
    user: string,
    password: string,
    poolData: ICognitoUserPoolData,
    region: string,
    identityPool: string,
  ): Observable<void> {
    const authenticationData = {
      Username: user,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: user,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    const self = this;
    return new Observable((subscriber) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          const cognitoGetUser = userPool.getCurrentUser();
          if (cognitoGetUser) {
            cognitoGetUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                subscriber.error(err);
              } else {
                self.buildCognitoCreds(
                  session.getIdToken().getJwtToken(),
                  poolData,
                  region,
                  identityPool,
                );
                this.setUserTokenAndAttributesToLocalStorage(session, cognitoUser, subscriber);
              }
            });
          } else {
            subscriber.error({
              reason: 'USER_NOT_FOUND',
            });
          }
        },
        onFailure: (err) => {
          this.authFailureProcessesor(err, subscriber, user);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          subscriber.error({
            reason: 'FORCE_CHANGE_PASSWORD',
            user: {
              username: user,
            },
          });
        },
      });
    });
  }

  private setUserTokenAndAttributesToLocalStorage(
    session: CognitoUserSession,
    cognitoUser: CognitoUser,
    subscriber: Subscriber<void>,
  ) {
    // store jwt token in storage
    this.tokenStorageService.setAccessToken(session.getIdToken().getJwtToken());
    this.tokenStorageService.setAccessTokenExpiration(session.getAccessToken().getExpiration());
    this.tokenStorageService.setRefreshToken(session.getRefreshToken().getToken());
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        console.error(err);
        subscriber.error(err);
      } else {
        // Find the email attribute and log it
        const emailAttribute = result.find((attr) => attr.getName() === 'email');
        if (emailAttribute) {
          localStorage.setItem('email', emailAttribute.getValue());
          subscriber.next();
        } else {
          const err = new Error('User does not have an email attribute');
          console.error(err);
          subscriber.error(err);
        }
      }
    });
  }

  private hasToChangePassword = (code: string): boolean => {
    return code === 'PasswordResetRequiredException' || code === 'UserNotConfirmedException';
  };

  private notConform = (code: string): boolean => {
    return code === 'InvalidParameterException';
  };

  private isUserNotFoundOrPasswordIncorrect = (code: string, message: string): boolean => {
    return (
      code === 'UserNotFoundException' ||
      (code === 'NotAuthorizedException' && message == 'Incorrect username or password.')
    );
  };

  private isUserDisabled = (code: string, message: string): boolean => {
    return (
      code === 'UserNotConfirmedException' ||
      (code === 'NotAuthorizedException' && message == 'User is disabled.')
    );
  };

  private authFailureProcessesor(err: any, subscriber: Subscriber<void>, user: string) {
    if (this.hasToChangePassword(err.code)) {
      subscriber.error({
        reason: 'FORCE_CHANGE_PASSWORD',
        user: {
          username: user,
        },
      });
    } else if (this.notConform(err.code)) {
      subscriber.error({
        reason: 'USER_NOT_CONFORM_POLICY',
      });
    } else if (this.isUserNotFoundOrPasswordIncorrect(err.code, err.message)) {
      subscriber.error({
        reason: 'USER_OR_PASSWORD_INCORRECT',
      });
    } else if (this.isUserDisabled(err.code, err.message)) {
      subscriber.error({
        reason: 'USER_DISABLED',
      });
    } else {
      console.error(err);
      subscriber.error({
        reason: 'USER_OR_PASSWORD_INCORRECT',
      });
    }
  }

  isLoggedIn(): Observable<boolean> {
    return this.getAwsConfig()
      .pipe(
        mergeMap(([poolData, region, identityPool]) => {
          this.buildCognitoCreds(
            this.tokenStorageService.getAccessToken(),
            poolData,
            region,
            identityPool,
          );
          return this.isLoggedInUserPool(poolData);
        }),
      )
      .pipe(map((awsLogged) => awsLogged && this.storage.getUserInfo() !== null));
  }

  private isLoggedInUserPool(poolData: ICognitoUserPoolData): Observable<boolean> {
    const userPool = new CognitoUserPool(poolData);
    const currentUser = userPool.getCurrentUser();
    return new Observable((observer) => {
      if (currentUser) {
        currentUser.getSession((sessionError: Error, session: CognitoUserSession | null) => {
          const expiration = this.tokenStorageService.getAccessTokenExpiration();
          if (sessionError) {
            console.error(sessionError);
            observer.next(false);
            return observer.complete();
          } else if (!session.isValid() || expiration <= Date.now() / 1000) {
            this.refreshAccessToken().subscribe({
              next: () => {
                observer.next(true);
                return observer.complete();
              },
              error: (err) => observer.error(err),
            });
          } else {
            observer.next(true);
            return observer.complete();
          }
        });
      } else {
        observer.next(false);
        return observer.complete();
      }
    });
  }

  refreshAccessToken(): Observable<string> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region, identityPool]) => {
        return this.refreshTokenUserPool(poolData, region, identityPool);
      }),
    );
  }

  private refreshTokenUserPool(
    poolData: ICognitoUserPoolData,
    region: string,
    identityPool: string,
  ): Observable<string> {
    const userPool = new CognitoUserPool(poolData);
    const currentUser = userPool.getCurrentUser();

    return new Observable((observer) => {
      if (currentUser) {
        currentUser.getSession((sessionError: Error | null, session: CognitoUserSession | null) => {
          if (sessionError) {
            observer.error(sessionError);
          } else {
            currentUser.refreshSession(
              session.getRefreshToken(),
              (refreshError, refreshSession) => {
                if (refreshError) {
                  observer.error(refreshError);
                } else {
                  this.buildCognitoCreds(
                    refreshSession.getIdToken().getJwtToken(),
                    poolData,
                    region,
                    identityPool,
                  );
                  // store jwt token in storage
                  this.tokenStorageService.setAccessToken(
                    refreshSession.getIdToken().getJwtToken(),
                  );
                  this.tokenStorageService.setAccessTokenExpiration(
                    refreshSession.getAccessToken().getExpiration(),
                  );
                  this.tokenStorageService.setRefreshToken(
                    refreshSession.getRefreshToken().getToken(),
                  );
                  observer.next(refreshSession.getIdToken().getJwtToken());
                  return observer.complete();
                }
              },
            );
          }
        });
      } else {
        observer.error({ reason: 'no user' });
      }
    });
  }

  changePassword(user: string, oldpassword: string, newpassword: string): Observable<void> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region, identityPool]) => {
        return this.changePasswordUserPool(user, oldpassword, newpassword, poolData);
      }),
    );
  }

  private changePasswordUserPool(
    user: string,
    oldpassword: string,
    newpassword: string,
    poolData: ICognitoUserPoolData,
  ): Observable<void> {
    const authenticationData = {
      Username: user,
      Password: oldpassword,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: user,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Observable((subscriber) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (r) => {
          // nothing to do
        },
        onFailure: (err) => {
          if (
            err.code === 'PasswordResetRequiredException' ||
            err.code === 'NotAuthorizedException'
          ) {
            cognitoUser.confirmPassword(oldpassword, newpassword, {
              onSuccess() {
                subscriber.next();
              },
              onFailure(e) {
                subscriber.error({
                  reason: 'USER_NOT_FOUND',
                });
              },
            });
          } else {
            console.error(err);
            subscriber.error({
              reason: 'USER_NOT_FOUND',
            });
          }
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          cognitoUser.completeNewPasswordChallenge(
            newpassword,
            {},
            {
              onSuccess: (res) => {
                subscriber.next();
              },
              onFailure: (err) => {
                if (err.code === 'InvalidPasswordException') {
                  subscriber.error({
                    reason: 'PASSWORD_NOT_CONFORM_POLICY',
                    user: {
                      username: user,
                    },
                  });
                } else {
                  subscriber.error(err);
                }
              },
            },
          );
        },
      });
    });
  }

  resetPassword(user: string): Observable<void> {
    return this.getAwsConfig().pipe(
      mergeMap(([poolData, region, identityPool]) => {
        return this.resetPasswordUserPool(user, poolData);
      }),
    );
  }

  private resetPasswordUserPool(user: string, poolData: ICognitoUserPoolData): Observable<void> {
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: user,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Observable((subscriber) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          subscriber.next();
        },
        onFailure: (err) => {
          if (err.name === 'InvalidParameterException' || err.name === 'UserNotFoundException') {
            subscriber.error({
              reason: 'USER_NOT_CONFORM_POLICY',
            });
          } else {
            subscriber.error(err);
          }
        },
      });
    });
  }

  private voidActionAndReturnNext(err, ob, u) {
    if (err) {
      console.error(err);
      ob.error(err);
    } else {
      ob.next(u);
      return ob.complete();
    }
  }

  private adminAddUserToGroup(
    p: { GroupName: string; UserPoolId: string; Username: string },
    ob: Subscriber<AwsUser | User>,
    cognitoidentityserviceprovider: CognitoIdentityProvider,
    user: User,
    u: User | AwsUser,
  ) {
    if (user.roles.includes(ADMIN_ROLE)) {
      cognitoidentityserviceprovider.adminAddUserToGroup(p, (err: any, d) =>
        this.voidActionAndReturnNext(err, ob, u),
      );
    } else {
      ob.next(u);
      return ob.complete();
    }
  }

  private adminRemoveUserFromGroup(
    p: { GroupName: string; UserPoolId: string; Username: string },
    ob: Subscriber<AwsUser | User>,
    cognitoidentityserviceprovider: CognitoIdentityProvider,
    user: User,
    u: AwsUser | User,
  ) {
    if (!user.roles.includes(ADMIN_ROLE)) {
      cognitoidentityserviceprovider.adminRemoveUserFromGroup(p, (err: any, d) =>
        this.voidActionAndReturnNext(err, ob, u),
      );
    } else {
      ob.next(u);
      return ob.complete();
    }
  }

  private adminEnableUser(
    p: { UserPoolId: string; Username: string },
    ob: Subscriber<User | AwsUser>,
    cognitoidentityserviceprovider: CognitoIdentityProvider,
    user: User,
    u: User | AwsUser,
  ) {
    if (user.enabled) {
      cognitoidentityserviceprovider.adminEnableUser(p, (err: any, d) =>
        this.voidActionAndReturnNext(err, ob, u),
      );
    } else {
      ob.next(u);
      return ob.complete();
    }
  }

  private adminDisableUser(
    p: { UserPoolId: string; Username: string },
    ob: Subscriber<AwsUser | User>,
    cognitoidentityserviceprovider: CognitoIdentityProvider,
    user: User,
    u: AwsUser | User,
  ) {
    if (!user.enabled) {
      cognitoidentityserviceprovider.adminDisableUser(p, (err, d) =>
        this.voidActionAndReturnNext(err, ob, u),
      );
    } else {
      ob.next(u);
      return ob.complete();
    }
  }
}
