import { Injectable, WritableSignal, signal } from '@angular/core';
import { User } from 'src/app/model/user.model';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private currentUserKey = 'currentUser';
  private pagesKey = 'pages';
  private storage: Storage = localStorage;
  private internalUser = signal<User | null>(null);
  public getItem = (key: string) => JSON.parse(this.storage.getItem(key));
  public setItem = (key: string, value: object) => this.storage.setItem(key, JSON.stringify(value));

  constructor() {
    this.internalUser.set(this.getUserInfo());
  }

  storeUserInfo(user: User) {
    this.storage.setItem(this.currentUserKey, JSON.stringify(user));
    this.internalUser.set(user);
  }

  removeUserInfo() {
    this.storage.clear();
    sessionStorage.clear();
    this.internalUser.set(null);
  }

  get user(): WritableSignal<User | null> {
    return this.internalUser;
  }

  getUserInfo(): User | null {
    try {
      const userInfoString = this.storage.getItem(this.currentUserKey);
      if (userInfoString) {
        return JSON.parse(userInfoString);
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  set pages(value: string[]) {
    this.storage.setItem(this.pagesKey, JSON.stringify(value));
  }
  get pages() {
    return JSON.parse(this.storage.getItem(this.pagesKey));
  }
}
