import { Component, EventEmitter, Output } from '@angular/core';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';

@Component({
  selector: 'app-tinymce',
  templateUrl: './tinymce.component.html',
  styleUrls: ['./tinymce.component.scss'],
})
export class TinymceComponent {
  @Output() tinymceInitialized = new EventEmitter<void>();

  handleOnInit() {
    this.tinymceInitialized.emit();
  }
}
