import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Injectable()
export class HomeRedirectGuardService {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.userService.isAdmin()) {
      this.router.navigate(['/admin']);
      return true;
    } else if (this.userService.isReferencer()) {
      this.router.navigate(['/referencer/home']);
      return true;
    } else if (this.userService.isContributor()) {
      this.router.navigate(['/contributor/home']);
      return true;
    } else {
      return false;
    }
  }
}
