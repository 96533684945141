import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map } from 'rxjs';
import { SessionService } from '../authentication/session.service';

@Injectable()
export class LocalizationService {
  constructor(
    private tranlationService: TranslateService,
    private sessionService: SessionService,
  ) {}

  getDefaultLang(): string {
    return this.tranlationService.getDefaultLang();
  }

  loadFromStorage(): Observable<any> {
    const user = this.sessionService.getUserInfo();
    if (user !== null) {
      return this.setLang(user.lang ?? this.getDefaultLang());
    }
    const lang = localStorage.getItem('lang');
    if (lang) {
      return this.setLang(lang);
    }
    return this.setLang(this.getDefaultLang());
  }

  getLang(): string {
    return this.tranlationService.currentLang;
  }

  setLang(newLang: string): Observable<any> {
    return this.tranlationService.use(newLang).pipe(
      map(() => {
        localStorage.setItem('lang', newLang);
      }),
      catchError((err) => {
        console.error(err);
        throw err;
      }),
    );
  }

  getSupportedLangs(): string[] {
    return this.tranlationService.getLangs();
  }
}
